import React from 'react';

const Hero = () => {
  return (
    <section id="home" className="hero">
      <h1>Welcome to Elegant Salon</h1>
      <p>Experience luxury beauty treatments at affordable prices.</p>
    </section>
  );
};

export default Hero;
