import React from 'react';

const Contact = () => {
  return (
    <section id="contact" className="contact">
      <h2>Contact Us</h2>
      <p>Email: contact@elegantsalon.com</p>
      <p>Phone: +123 456 7890</p>
    </section>
  );
};

export default Contact;
