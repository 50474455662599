import React from 'react';

const Services = () => {
  return (
    <section id="services" className="services">
      <h2>Our Services</h2>
      <div className="service-list">
        <div className="service-item">
          <h3>Haircut</h3>
          <p>Trendy haircuts by professional stylists.</p>
        </div>
        <div className="service-item">
          <h3>Facial</h3>
          <p>Relaxing facials that rejuvenate your skin.</p>
        </div>
        <div className="service-item">
          <h3>Manicure & Pedicure</h3>
          <p>Pamper yourself with our luxurious treatments.</p>
        </div>
      </div>
    </section>
  );
};

export default Services;
