import React from 'react';

const Testimonials = () => {
  return (
    <section id="testimonials" className="testimonials">
      <h2>What Our Clients Say</h2>
      <div className="testimonial-item">
        <p>"Amazing service! My haircut was perfect!" - Sarah</p>
      </div>
      <div className="testimonial-item">
        <p>"The facial left my skin glowing!" - Emily</p>
      </div>
    </section>
  );
};

export default Testimonials;
